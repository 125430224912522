.processpage{
  .processpage1{
    display: flex;
   // justify-content: center;
   // align-items: center;
    height: 85vh;
    padding:3rem;
    .processinfoleft{
      flex: 1;
     width: 50%;
      padding: 3rem;
      .processinfoheading{
        font-size: 55px;
      }
      p{
        color :rgba(80, 81, 80, 1);
      }
      button,button:hover{
        background-color: #0ff4bd;
    color: black;
    font-weight: bold;
    height: 50px;
    width: 131px;
    border-radius: 11px;
    border: transparent;
    font-size: 15px;
      }
    }
    .rightcontent{
      width: 50%;
      display: flex;
      overflow-x: auto;
    }
    .processinforight{
      //background-color: rgba(248, 248, 248, 1);
    //  flex: 1;
     //width: 50%;
      border-radius: 15px;
      padding:3rem;
      width: 100%;
     // width: 58%;
     // padding: 10px;
      height: 100%;
      margin-right: 10px;
      .processcontent{
        height :50%;
        padding: 1rem;
        width: 350px;
        p{
          font-size: 13px;
        }
      }
      .processimage{
        height :50%;
        width: 350px;
      }
      img{
        width: 100%;
        height: 100%;
        border-radius:0px 0px 15px 15px;
       // object-fit: cover;
      }
    }
   .processinfolast{
    flex: 1;
   }
  }
  .processpage2{
    background-color: black;
    height: 100vh;
    width: 100%;
    display: flex;
    padding-top: 18vh;
    .left{
     flex: 1;
     display: flex;
     align-items: center;
     justify-content: center;
     img{
      width: 500px;
      height: 595px;
      object-fit: contain;
     }
    }
    .right{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      padding :5rem;
      h1{
        padding-bottom: 3rem;
      }
      p{
        color: rgba(186, 186, 186, 1);

      }
    }
  }
  .processpage3{
   max-height: 100vh;
   padding: 2rem;
    .processheading{
      display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left{
      width: 40%;
      p{
      color: rgba(80, 81, 80, 1);
      font-size: 15px;
      }
    }
    .right{
     span{
      margin: 10px;
     }
    }
    }
    .processsliding{
      display: flex;
      margin-top: 20px;
      .singlecard{
        width: 33%;
        background-color: rgba(248, 248, 248, 1);
        border-radius: 20px;
        margin-right: 10px;
        height:60vh;
        //height: 300px;
       // padding: 2rem;
        .img{
          margin-bottom: 20px;
          width:100%;
          height:50%;
        }
      }
      .singlecardinfo{
        padding:0px 2rem 0px 2rem;
      }
      .main {
        width: 100%;
        overflow: hidden;
        position: relative; /* Ensure the buttons and slider stay in the same container */
      }
      
      .App {
        display: flex;
        transition: transform 0.3s ease; /* Add transition for smooth sliding */
      }
      
      .slider {
        flex: 0 0 auto;
        width: 25%;
        margin-right: 20px;
        background-color: #f0f0f0;
        padding: 20px;
        box-sizing: border-box; /* Include padding in width */
      }
      
    }
  }
  .getstartedbtn{
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px;
    button,button:hover{
      height: 60px;
    background-color: #0ff4bd;
    color: black !important;
    width: 17%;
    font-weight: 500;
    font-size: 20px;
    border-radius: 15px;
    border: transparent;
    }
  }
}
@media(max-width:1400px)
{
  .processpage .processpage2 {
    height: 101vh ;
  }
  .processpage2 .left img {
    height: 526px !important;
}
.processpage .processpage3 {
  max-height: 100vh !important;
}
// .processpage .processpage2 {
//   height: 102vh !important;
// }
}
@media(max-width:1024px)
{
  .processpage .processpage1 {
    height:60vh;
  }
  .processpage2
{
  flex-direction: column !important;
  height: 82vh !important;
  padding-top: 0px !important;
}
.processpage .getstartedbtn button, .processpage .getstartedbtn button:hover {
  width: 22% !important;
}
.processpage .processpage3 .processsliding .singlecard .img {
  height:25%
}
.processpage .processpage3 .processsliding .singlecard {
  height:33vh;
}

.processpage .processpage1 .processinforight {
  padding :10px;
  height: 50%;
  img{
    object-fit: contain;
  }
}
.processpage .getstartedbtn {
  height  :10vh;
}
}
// .processpage .processpage3 {
//  // height: 47vh !important;}
// }
@media(max-width:820px)
{
  .processpage2{
    height:100vh !important;
  }
  .processpage .processpage1 {
    flex-direction: column;
    margin: 10px;
    .processinfoleft{
      width:100%
    }
   
    .rightcontent{
      width:100%;
      img{
      height:200px;
      }
    }
}

  .processpage .processpage3 .processsliding .singlecard{
    height:50vh;
  }
  .processpage .processpage3 {
    height: 67vh !important;}
}
@media(max-width:768px)
{
  .processpage2{
    height:114vh !important;
  }
  .processpage .processpage1 {
    height: 78vh !important;
    .processpage .processpage1 .processinfoleft {
      padding: 1rem;
  }
  .rightcontent{
    width:100%;
    img{
    height:200px;
    }
  }
}

  .processpage .processpage3 {
    height: 73vh !important;}
    
  }
@media (min-width:1800px) {
  .processpage .processpage3 {
    height: 77vh;
  }  
}
@media only screen and (min-width: 1800px) and (max-height: 835px)  {
  .processpage .processpage3 {
    height: 84vh !important;
  }
}
@media only screen and (min-width: 1800px) and (max-height: 620px)  {
  .processpage .processpage3 {
    height: 88vh !important;
  }
}
@media (max-width: 1350px) {
  .processpage .processpage2 {
    height: 122vh !important;
  }
  .processpage .processpage1 {
    height: 100vh !important;
  }
  .processpage .processpage3 .processsliding .singlecard {
    height: 72vh !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;