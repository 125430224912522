.client-select-container {
  .ant-input:hover,.ant-input:focus{
    border-color: #0FF4BD !important;
    border-inline-end-width: 1px;
}
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ::placeholder {
    color: rgba(161, 161, 170, 0.4);
    /* Placeholder text color */
    font-family: "Poppins",sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* Adjust as needed */
  }
  
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: black !important;
    font-weight: bold;
  }
  #dec-form{
    width: 60%;
    .subform{
      width: 100%;
      display: flex;
      
    .contractor-submitbtn,.contractor-submitbtn:hover {
      width: 60%;
      height: 6vh;
      background-color: #0FF4BD !important;
      color: black !important;
      font-weight: bold;
      margin: 1% auto;
      border-radius: 15px;
      border: none;
     cursor:pointer;
    }
  
   
    .contractor-submitbtnd,.contractor-submitbtnd:hover {
      width: 60%;
      height: 6vh;
      background-color: #E2E2E7 !important;
      color: #505150 !important;
      font-weight: 700;
      margin: 2% auto;
      border-radius: 15px;
      border: none;
      cursor:pointer;
    }
  }
  .questionsinfo{
    height: calc(90vh - 300px);
    overflow-y: scroll;
    .inputtag1{
      input{
      background:  rgba(15, 244, 189, 0.07) !important;
      border-color: #0FF4BD;
      }
      
    }
    .question1{
      color: #505150;
      font-weight: 600;
      font-size: 15px;
    }
    .question
    {
      .questionbuttons{
       
        .questionbtn{
           width: 48%;
           button{
              width: 100%;
              border-radius: 14px;
              padding: 5px;
              height: 44px;
              border-color: transparent;
              background-color: #0FF4BD;
              font-weight: 500;
           }
        }
        .questionbtnd{
          width: 48%;
          button{
            width: 100%;
            border-radius: 14px;
            padding: 5px;
            height:44px;
            background-color: #F6F6FB;
            color: black;
            border-color: transparent;
            font-weight: 500;
         }
        }
      }
    
      .questionyears{
        .inputSelected{
          width:50%;
          margin-right: 2%;
          .ant-input-affix-wrapper, .ant-input-affix-wrapper:hover,.ant-input-affix-wrapper:active,.ant-input-affix-wrapper:visited
          {
          background:  rgba(15, 244, 189, 0.07) !important;
          border-color: #0FF4BD;
          }
          .ant-input-suffix{
            width: 78% !important;
          }
          input{
          height: 50px;
          background:  rgba(15, 244, 189, -0.07) !important;
          }
        }
        .inputnotselected{
          width:50%;
          margin-right: 2%;
          .ant-input-suffix{
            width: 78% !important;
          }
          input{
            height: 50px;

          }
          
        }
        .input1{
          width:48%;
          height: 40px;
          input{
            height: 50px;
            }
        }
      }
    }
  }
  }
  .contractor_center_container {
    width: 90%;
    height: 90vh;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    .contractor-content-data {
      width: 60%;

      .heading {
        margin: auto;
        text-align: center;
        margin-bottom: 2%;
        color: black;
        font-weight: 700;
        font-size: 24px;

        .skipcard_color {
          color: #0FF4BD;
          text-align: end;
          font-family: "Poppins",sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          /* 200% */
        }
      }
    }

    .question_select {
      //  height: 7.33vh;
      // height:50px;
      padding: 10px;
      flex-shrink: 0;
      border-radius: 0.9375rem;
      border: 1px solid #a1a1aa;
      background: #fff;
      // padding: 3% 5% 3% 5%;
      //  padding-top:4% ;
      color: #20222A;

      font-family: 'Poppins',sans-serif;
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      /* 1.59375rem */
      /* 1.59375rem */
      .ant-select-dropdown {
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {

          background-color: #0FF4BD;
        }
      }

      .ant-select-arrow {
        color: #20222A;
      }

      &::placeholder {
        color: rgba(161, 161, 170, 0.4);
        /* Placeholder text color */
        font-family: 'Poppins',sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* Adjust as needed */
      }

    }

    .valid_question_select {
      //   height: 7.33vh;
      height: 50px;
      flex-shrink: 0;
      border-radius: 0.9375rem;
      border: 1px solid #0FF4BD;
        background:rgba(15, 244, 189, 0.07);
      // padding: 3% 5% 3% 5%;
      padding: 10px;
      //  color:#20222A;

      font-family: 'Poppins',sans-serif;
      font-size: 1.0625rem;
      font-style: normal;
      //  font-weight: 500;
      //  line-height: 150%; 
    }

    .map-content-data {
      width: 75%;
      position: relative;

      .heading {
        margin: auto;
        text-align: center;
        margin-bottom: 0.5%;
        color: black;
        font-weight: 700;
        font-size: 24px;
      }

      .map_km {
        //position: relative;font-size: 20px;font-weight: 700;text-align: center;top: calc(50% - 20px);4
        z-index: 1111;
        /* justify-content: center; */
        position: absolute;
        display: flex;
        left: 46%;
        top: 38%;
        background-color: #fff;
      }

      .ant-slider {
        
        .ant-slider-handle::before {
          
          background: transparent;
        }
      }
    }

    .profile_pic_content {
      display: flex;
      flex-direction: column;
      align-items: center;

      // width: 38.96%;
      //     height: 43.8vh;
      //  gap: 1.28rem;
      // gap: 1.875rem;
      .profilepic_box {
        position: relative;

        .profilepic_wrapper {

          // width: 38.96%;
          // height: 43.8vh;
          .editicon {
            //right: -4%;
            bottom: 0%;
            position: absolute;
            background-color: #0FF4BD;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            left: 92px;
            border: 2px solid #fff;

            img {
              width: 22px;
              height: 22px;
              margin-left: 8px;
              margin-top: 6px;
            }
          }

          .profilepic_circle {
            width: 125px;
            height: 125px;
            flex-shrink: 0;
            border-radius: 7.1875rem;
            border: 1px solid rgba(25, 183, 146, 0.2);
            background: rgba(25, 183, 146, 0.1);
            object-fit: cover;

            .edit_circle {
              width: 3.125rem;
              height: 3.125rem;
              flex-shrink: 0;
              border-radius: 4.0625rem;
              border: 2px solid #fff;
              background: #0ff4bd;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 70%;
              top: 60%;
            }
          }
        }
      }

      .desc_container {
        h1 {
          // display: flex;
          // align-items: flex-start;
          color: #505150;
          font-size: 1.1rem;
          font-style: normal;
          font-weight: 500;
          // line-height: 150%; /* 1.875rem */

          // margin: 0 0 0.94rem 0;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .desc_textarea {
          border-radius: 0.75rem;
          border: 1px solid var(--grey-border, #a1a1aa);
          width: 30rem;
          height: 125px;
          flex-shrink: 0;
          color: black;
          font-family: "Poppins",sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 500;
          //line-height: 150%; /* 1.59375rem */
          padding: 0.94rem 1.25rem 0.94rem 1.25rem;
        }

        .valid_desc_textarea {
          width: 30rem;
          height: 125px;
          border-radius: 0.75rem;
          border: 1px solid #0ff4bd;
          background: rgba(15, 244, 189, 0.07);
          color: black;
          font-family: "Poppins",sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 500;
          // line-height: 150%; /* 1.59375rem */
          padding: 0.94rem 1.25rem 0.94rem 1.25rem;
        }
      }


    }

    .progress_container {
      width: 60%;
      padding-top: 4.5rem;
      flex-shrink: 0;
      background: #fff;
    }

    .num_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 446px;
      height: 429px;
      gap: 40px;

      .para_container {
        width: 25.125rem;
        color: var(--2, #505150);
        // text-align: center;
        display: flex;
        flex-direction: column;
        // align-items: center;
        text-align: center;

        /* 16_Body_1 */
        font-family: "Poppins",sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;

        /* 187.5% */
        p {
          margin-bottom: 0rem;
        }
      }

      .num_field {
        width: 25rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;

        color: rgba(161, 161, 170, 0.4);
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 1.59375rem */
      }

      .select_field {
        // width: 25rem;
        height: 4.375rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        border: 1px solid #a1a1aa;
        background: #fff;

        color: rgba(161, 161, 170, 0.4);
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 1.59375rem */
      }

      .input-container {
        position: relative;

        .ant-input {
          margin-left: 2.44rem;
          font-family: "Poppins",sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          /* 1.59375rem */
          padding-top: 1%;

        }

        .ant-select-selector {
          .ant-select-selection-item {
            color: var(--black, #20222a);
            font-family: "Poppins",sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 1.59375rem */
          }
        }
      }

      .prefix {
        position: absolute;
        top: 33%;
        left: 30px;
        z-index: 10;
        width: 5.5rem;
        height: 4.38rem;

        .country_code {
          color: var(--black, #20222a);
          font-family: "Poppins",sans-serif;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }

      .input-element {
        padding-left: 80px;
      }

      .validated_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid #0ff4bd;
        background: transparent;

        background: rgba(15, 244, 189, 0.07);
      }

      .invalid_input_field {
        width: 25rem;
        height: 4.375rem;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #20222a;
        border-radius: 0.9375rem;
        border: 1px solid red;
        background: rgba(239, 94, 147, 0.07);
      }

      .ant-form-item-control-input-content {
        .ant-input {
          background: transparent;

          .ant-input-lg {
            color: var(--black, #20222a);
            font-family: "Poppins",sans-serif;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 1.59375rem */
          }
        }
      }

      .login_form_button_disabled {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 2.81rem;
        border-radius: 0.9375rem;
        background: #e2e2e7;
        color: rgba(80, 81, 80, 0.5);
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
        /* 235.294% */
      }

      .login_form_button {
        display: flex;
        width: 25rem;
        height: 3.75rem;
        padding: 0.625rem 8.375rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.9375rem;
        background: #0ff4bd;
        color: #20222a;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
      }

      .inputfield_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.25rem;
        justify-content: center;
      }
    }

    .profiledetails {
      margin:auto ;
      .heading {
        margin: auto;
        text-align: center;
      }
      .k_map{
        position: absolute;
    font-size: 14px;
    font-weight: 700;
    z-index: 1111;
    display: flex;
    left: 46%;
    top: 39%;
    padding: 5px;
    border-radius: 6px;
    border: 1.5px solid #0FF4BD;
    background-color: rgb(255, 255, 255);
    min-width: 30px;
    max-width:60px;
    }
//   .k-triangle::after{
//     content: "";
// position: absolute;
// z-index:10000;
// left: 49%;
// top: 49%;
// margin-left: -8px;
// border-width: 19px;
// border-style: solid;
// border-left:10px solid transparent ;
// border-right:10px solid transparent ;
// border-bottom:10px solid transparent;
// color:#fff;
// //border-color: #0FF4BD;
// // border-left: 10px solid transparent;
// // border-right: 10px solid transparent;
// // border-bottom: 10px solid transparent;
//   }
  

      .profileform {
        .subform {
          display: flex;
          flex-direction: row;
          width: 100%;
        }

        .contractor-subform {
          width: 100%;

          .multi_select_field {
            height: 6.5vh;
            width: 98%;
            border-radius: 15px;
            border: 1px solid #c8c8cd;
            background: #fff;
            display: flex;
            text-align: center;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem;

            .place_holder {
              color: rgba(161, 161, 170, 0.4);
              font-family: "Poppins",sans-serif;
              font-size: 14px;
              font-style: normal;
              // font-weight: 500;
              //line-height: 150%;
            }
          }

          .ant-form-item {
            margin-bottom: 16px;
          }
        }

        .options_board {
          width: 53%;
          height: 36%;
          overflow-y: scroll;
          z-index: 2;
          position: absolute;
          display: flex;
          display: inline-flex;
          padding: 10px;
          // padding: 1.5rem 2.125rem;
          flex-direction: column;
          align-items: flex-start;
          // gap: 0.625rem;

          border-radius: 11px;
          border: 1px solid #ececee;
          background: #fff;

          .option_list_title {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            // gap: 0.9375rem;
            h1 {
              color: #505150;

              font-family: "Poppins",sans-serif;
              font-size: 1.1rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .option_list {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.9375rem;

              .options {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 0.9rem;

                .ant-divider {
                  margin: 0px;
                }

                .option_container {
                  // width: 47rem;
                  height: 2.5rem;
                  display: flex;
                  justify-content: space-between;

                  .checkbox-round {
                    width: 1.5rem;
                    height: 1.5rem;

                    vertical-align: middle;

                    cursor: pointer;
                  }

                  .option_img_text {
                    display: flex;
                    gap: 0.62rem;

                    .img_profile {
                      img {
                        width: 2.5rem;
                        height: 2.5rem;
                        border-radius: 2.5rem;
                      }
                    }
                  }

                  .option_radio {
                    width: 1.5rem;
                    height: 1.5rem;
                    flex-shrink: 0;
                  }
                }
              }
            }
          }
        }

        .checkedItem {
          display: flex;
          padding: 0.625rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          border-radius: 0.625rem;
          border: 1px solid var(--light-border, #ececee);
          background: var(--white, #fff);
          width: max-content;

          .checkedItem_img {
            img {
              height: 40px;
              width: 40px;
              border-radius: 50%;
            }
          }
        }

        .contractor-submitbtn {
          width: 40%;
          height: 6vh;

          background-color: #0FF4BD !important;
          color: black !important;
          font-weight: bold;
          margin: 2% auto;
          border-radius: 15px;
          border: none;
        }

        .contractor-submitbtnd {
          width: 40%;
          height: 6vh;
          background-color: #E2E2E7 !important;
          color: #505150 !important;
          font-weight: 700;
          margin: 2% auto;
          border-radius: 15px;
          border: none;
          pointer-events: none;
        }

        .subform1 {
          width: 100%;

          // width:100%;
          .input-fieldcolor {
            background-color: rgba(15, 244, 189, 0.07);
            border-radius: 15px;
            border: 1px solid #0FF4BD;
            //   opacity: 0.2;
            color: black;
            // font-weight: 700;
          }

          input {
            height: 6.5vh;
            width: 96%;
            border-radius: 15px;
            border: 1px solid #c8c8cd;

            &::placeholder {
              color: rgba(161, 161, 170, 0.4);
              /* Placeholder text color */
              font-family: "Poppins",sans-serif;
              font-size: 14px;
              font-style: normal;
              // line-height: 150%; /* Adjust as needed */
            }
          }
        }

        .skipcard {
          color: #000;
          text-align: center;
          font-family: "Poppins",sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          opacity: 0.4;
        }


      }
    }

    .profile-submitbtn {
      width: 80%;
      height: 6vh;

      background-color: #0FF4BD !important;
      color: black !important;
      font-weight: bold;
      margin: 3% auto;
      border-radius: 15px;
      border: none;
    }

    .profile-submitbtnd {
      width: 80%;
      height: 6vh;
      background-color: #E2E2E7 !important;
      color: #505150 !important;
      font-weight: 700;
      margin: 3% auto;
      border-radius: 15px;
      border: none;
      pointer-events: none;
    }

    .map-submitbtn {
      width: 27.81%;
      height: 6vh;

      background-color: #0FF4BD !important;
      color: black !important;
      font-weight: bold;
      // margin: 3% auto;
      border-radius: 15px;
      border: none;
    }

    .map-submitbtnd {
      width: 27.81%;
      height: 6vh;
      background-color: #E2E2E7 !important;
      color: #505150 !important;
      font-weight: 700;
      // margin: 3% auto;
      border-radius: 15px;
      border: none;
      pointer-events: none;
    }
  }

  .google-autosuggestion-list {
    margin-top: 0px;
    width: 100%;
    height: 154px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    margin-bottom: 108px;
    position: absolute;
    z-index: 1;
    background: #fff;
    padding: 0 10px;
    overflow-y: auto;
  }
}

.map-km {
  //position: relative;font-size: 20px;font-weight: 700;text-align: center;top: calc(50% - 20px);4
  z-index: 1111;
  /* justify-content: center; */
  position: absolute;
  display: flex;
  left: 46%;
  top: 38%;
  background-color: #fff;
}

.ant-input:focus {
  // border-color: #4096ff;
 // border:1px solid #0FF4BD !important;
  box-shadow: none !important;
}

.ant-slider:hover .ant-slider-handle::after {
  display: none !important;
}

.ant-slider .ant-slider-handle::after {
  display: none !important;
}

.profilepicmodal {
  width: 400px !important;
  .ant-modal-content {
    width: 100% !important;
  }
}
.bankdetailscontainer{
 position: relative;
  .skipoption{
    position: absolute;
    right: 10%;
    top:2%;
    font-size: 18px;
    color: #0FF4BD;
    font-weight: 600;
    cursor: pointer;
  }
  .subform {
    display: flex;
    flex-direction: row !important;
  }
.bankform{
  .ant-form-item {
    width: 50% !important;
  }
  .input-field, .input-field:hover {
    height: 50px;
    width: 97%;
    border-radius: 15px;
    border: 0.1px solid #d9d9d9 !important;
}
.input-field1{
  background-color: rgba(15, 244, 189, 0.07) !important;
  height: 50px;
    width: 97%;
    border-radius: 15px;
    border: 0.1px solid #0FF4BD;
}
}
  .subform1 {
    flex: 1;
    .input-fieldcolor {
      background-color: rgba(15, 244, 189, 0.07);
      border-radius: 15px;
      border: 1px solid #0FF4BD;
      //   opacity: 0.2;
      color: black;
      // font-weight: 700;
    }
    .input-field1 {
      border-radius: 15px;
    }
    input {
      width: 94% !important;
    }
  }
  .nextbtnd{
    width:40%;
    height:50px;
    border: none;
  }
  .nextbtn,.nextbtn:hover{
    width:40%;
    height:50px;
    background-color: #0FF4BD !important ;
    color:#000 !important;
    font-weight: 500;
  }
}

.stripemodal .ant-modal-content{
  padding:0px !important;
  h4{
    padding: 20px;
  }
  .stripecontent{
    height:50vh;
    overflow-y: scroll;
    padding: 20px;
   

  }
  .tncchecked,.tncchecked:hover{
    background: #0FF4BD;
    color: #20222A;
    font-weight: 600;
  }
}
.contractor-subform {
  width: 100%;

  .multi_select_field {
    height: 6.5vh;
    width: 98%;
    border-radius: 15px;
    border: 1px solid #c8c8cd;
    background: #fff;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    .place_holder {
      color: rgba(161, 161, 170, 0.4);
      font-family: "Poppins",sans-serif;
      font-size: 14px;
      font-style: normal;
      // font-weight: 500;
      //line-height: 150%;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
}

.options_board {
  width: 53%;
  height: 36%;
  overflow-y: scroll;
  z-index: 2;
  position: absolute;
  display: flex;
  display: inline-flex;
  padding: 10px;
  // padding: 1.5rem 2.125rem;
  flex-direction: column;
  align-items: flex-start;
  // gap: 0.625rem;

  border-radius: 11px;
  border: 1px solid #ececee;
  background: #fff;

  .option_list_title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    // gap: 0.9375rem;
    h1 {
      color: #505150;

      font-family: "Poppins",sans-serif;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .option_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.9375rem;

      .options {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;

        .ant-divider {
          margin: 0px;
        }

        .option_container {
          // width: 47rem;
          height: 2.5rem;
          display: flex;
          justify-content: space-between;

          .checkbox-round {
            width: 1.5rem;
            height: 1.5rem;

            vertical-align: middle;

            cursor: pointer;
          }

          .option_img_text {
            display: flex;
            gap: 0.62rem;

            .img_profile {
              img {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 2.5rem;
              }
            }
          }

          .option_radio {
            width: 1.5rem;
            height: 1.5rem;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.checkedItem {
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid var(--light-border, #ececee);
  background: var(--white, #fff);
  width: max-content;

  .checkedItem_img {
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }
}

@media(max-height:700px) {
  .client-select-container {
    .contractor_center_container {
      gap: 3rem !important;
    }
  }
  .contractor_center_container {
    height:95vh !important;
  }
}
@media(max-width:900px) {
  .bankdetailscontainer .skipoption {
    right: 0% !important;
  }
}
@media only screen and (max-height:620px)  and (min-width:1300px ){
  .client-select-container .contractor_center_container .profiledetails .profileform .contractor-submitbtn {
    margin:0% auto !important;
  }
  .client-select-container .contractor_center_container .profiledetails .profileform .contractor-submitbtnd {
    margin:0% auto !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;